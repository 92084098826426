import { get } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { extraTimePaySettingsSchema } from "@src/appV2/Facilities/api/useGetExtraTimePaySettings";
import { ShiftPeriod } from "@src/appV2/Shifts/Shift/types";
import {
  type QueryFunctionContext,
  type QueryKey,
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
  type UseInfiniteQueryResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { PreferredWorkerReason } from "./useFetchPreferredWorker";

export const shiftBlocksRequestSchema = z.object({
  filter: z.object({
    agentId: z.string(),
    facilityId: z.string().nullish(),
    qualification: z.string(),
  }),
  page: z
    .object({
      cursor: z.string().nullish(),
      size: z.number().nullish(),
    })
    .nullish(),
});

export type ShiftBlocksRequest = z.infer<typeof shiftBlocksRequestSchema>;

type FetchShiftBlockParams = Omit<ShiftBlocksRequest, "page">;

export const shiftBlockSchema = z.object({
  id: z.string(),
  type: z.literal("shift-block"),
  attributes: z.object({
    facilityId: z.string(),
    agentReq: z.string(),
    blockSequence: z.string(),
    blockStartTime: z.string(),
    blockEndTime: z.string(),
    totalPay: z.number(),
    totalTime: z.number(),
    notesForProfessionals: z.string().nullish(),
    priorityAccess: z
      .object({
        priorityTill: z.string().nullish(),
      })
      .nullish(),
    instantBook: z
      .object({ preferredReasons: z.array(z.nativeEnum(PreferredWorkerReason)).nullish() })
      .nullish(),
  }),
  relationships: z.object({
    shifts: z.object({
      data: z.array(
        z.object({
          type: z.string(),
          id: z.string(),
        })
      ),
    }),
    facility: z.object({
      data: z.object({
        type: z.string(),
        id: z.string(),
      }),
    }),
  }),
});

const facilityTypeMetadataSchema = z.object({
  type: z.literal("facility"),
  id: z.string(),
  attributes: z.object({
    name: z.string(),
    distance: z.number(),
    extraTimePaySettings: extraTimePaySettingsSchema.nullish(),
  }),
});
const shiftTypeMetadataSchema = z.object({
  type: z.literal("shift"),
  id: z.string(),
  attributes: z.object({
    name: z.nativeEnum(ShiftPeriod).nullish(),
    start: z.string(),
    end: z.string(),
    pay: z.number(),
    time: z.number(),
    agentId: z.string().nullish(),
    isInstantPay: z.boolean(),
    offer: z
      .object({
        id: z.string(),
        pay: z.object({ value: z.number(), currency: z.string() }),
        expiresAt: z.string(),
      })
      .nullish(),
  }),
});

export const shiftBlockIncludedMetaDataSchema = z.discriminatedUnion("type", [
  facilityTypeMetadataSchema,
  shiftTypeMetadataSchema,
]);

const shiftBlocksResponseSchema = z.object({
  data: z.array(shiftBlockSchema),
  included: z.array(shiftBlockIncludedMetaDataSchema),
  links: z.object({
    nextCursor: z.string().nullish(),
  }),
});

export type ShiftBlock = z.infer<typeof shiftBlockSchema>;

export type ShiftBlocksResponse = z.infer<typeof shiftBlocksResponseSchema>;
export type FacilityTypeMetadata = z.infer<typeof facilityTypeMetadataSchema>;
export type ShiftTypeMetadata = z.infer<typeof shiftTypeMetadataSchema>;

export type ShiftBlockWithMappedDetails = z.infer<typeof shiftBlockSchema>["attributes"] & {
  id: string;
  isShiftBlockAssigned: boolean;
  shifts: Array<ShiftTypeMetadata["attributes"] & { id: string }>;
  facility: FacilityTypeMetadata["attributes"] & { id: string };
};

export const shiftBlocksApiUrl = `${environmentConfig.REACT_APP_BASE_API_URL}/shift-blocks`;

export function useFetchPaginatedShiftBlocks(
  params: FetchShiftBlockParams,
  options: UseInfiniteQueryOptions<ShiftBlocksResponse, AxiosError> = {}
): UseInfiniteQueryResult<ShiftBlocksResponse> {
  const { filter } = params;
  return useInfiniteQuery({
    queryKey: [shiftBlocksApiUrl, params],
    queryFn: async ({
      pageParam: pageParameter = undefined,
    }: QueryFunctionContext<QueryKey, string | undefined>) => {
      const response = await get({
        url: shiftBlocksApiUrl,
        queryParams: {
          filter,
          page: {
            cursor: pageParameter,
            size: 10,
          },
          sort: "distance,startTime",
        },
        responseSchema: shiftBlocksResponseSchema,
        requestSchema: shiftBlocksRequestSchema,
      });
      return response.data;
    },
    getNextPageParam: (lastPage) => lastPage.links.nextCursor ?? undefined,
    ...options,
  });
}
