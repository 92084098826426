import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics/events";
import { z } from "zod";

export enum PreferredWorkerReason {
  INTERNAL_CRITERIA = "INTERNAL_CRITERIA",
  RATING = "RATING",
  FAVORITE = "FAVORITE",
}
export interface UseFetchWorkerPreferredStatusProps {
  workplaceId: string;
  workerId: string;
}

const getWorkerPreferredStatusRequestSchema = z.object({
  workplaceId: z.string(),
  workerId: z.string(),
});

const preferredWorkerSchema = z.object({
  id: z.string(),
  workplaceId: z.string(),
  workerId: z.string(),
  reason: z.nativeEnum(PreferredWorkerReason),
  updatedAt: z.string(),
  createdAt: z.string(),
});

const getWorkerPreferredStatusResponseSchema = z.object({
  preferred: z.array(preferredWorkerSchema),
});

export type PreferredWorker = z.infer<typeof preferredWorkerSchema>;
export type WorkerPreferredStatusApiResponseType = z.infer<
  typeof getWorkerPreferredStatusResponseSchema
>;

export function useFetchWorkerPreferredStatus(
  props: UseFetchWorkerPreferredStatusProps,
  options?: UseGetQueryOptions<WorkerPreferredStatusApiResponseType>
) {
  const { workplaceId, workerId } = props;
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_SHIFT_REVIEW_API_URL}/preferred-workers`,
    queryParams: { workplaceId, workerId },
    requestSchema: getWorkerPreferredStatusRequestSchema,
    responseSchema: getWorkerPreferredStatusResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_WORKER_PREFERRED_STATUS_FAILURE,
    },
    ...options,
  });
}
