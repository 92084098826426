import { type ApiResponse, post } from "@src/appV2/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

const createChatChannelRequestSchema = z.object({
  facilityId: z.string(),
  agentId: z.string(),
});

const createChatChannelResponseSchema = z.string();

type CreateChatChannelRequest = z.infer<typeof createChatChannelRequestSchema>;
type CreateChatChannelResponse = z.infer<typeof createChatChannelResponseSchema>;

export function useCreateChatChannel(
  options: UseMutationOptions<
    ApiResponse<CreateChatChannelResponse>,
    AxiosError,
    CreateChatChannelRequest
  > = {}
): UseMutationResult<ApiResponse<CreateChatChannelResponse>, AxiosError, CreateChatChannelRequest> {
  return useMutation({
    mutationFn: async (data: CreateChatChannelRequest) => {
      return await post({
        url: "/chat/channels",
        data,
        requestSchema: createChatChannelRequestSchema,
        responseSchema: createChatChannelResponseSchema,
      });
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.POST_CREATE_CHAT_CHANNEL_FAILURE,
    },
    useErrorBoundary: false,
    ...options,
  });
}
