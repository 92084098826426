import { patch } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

export enum ShiftBlockStatus {
  CANCELLED = "CANCELLED", // The HCP can only set this status, for the shift block
}

const updateShiftBlockRequestSchema = z.object({
  data: z.object({
    type: z.literal("shift-block"),
    id: z.string(),
    attributes: z.object({
      // This is being used for both booking and cancelling a shift block, hence the optional fields
      status: z.nativeEnum(ShiftBlockStatus).optional(),
      agentId: z.string().optional(),
      offers: z
        .array(
          z.object({
            id: z.string(),
            shiftId: z.string(),
          })
        )
        .optional(),
    }),
  }),
});

const updateShiftBlockResponseSchema = z.object({
  data: z.object({
    id: z.string(),
    type: z.literal("shift-block"),
  }),
});

export type UpdateShiftBlockRequest = z.infer<typeof updateShiftBlockRequestSchema>;
export type UpdateShiftBlockResponse = z.infer<typeof updateShiftBlockResponseSchema>;

export interface UpdateShiftBlockParams {
  shiftBlockId: string;
}

export function useUpdateShiftBlock(
  params: UpdateShiftBlockParams,
  options: UseMutationOptions<UpdateShiftBlockResponse, AxiosError, UpdateShiftBlockRequest> = {}
): UseMutationResult<UpdateShiftBlockResponse, AxiosError, UpdateShiftBlockRequest> {
  const { shiftBlockId } = params;
  return useMutation({
    mutationFn: async (data: UpdateShiftBlockRequest) => {
      const response = await patch({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/shift-blocks/${shiftBlockId}`,
        data,
        requestSchema: updateShiftBlockRequestSchema,
        responseSchema: updateShiftBlockResponseSchema,
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.UPDATE_SHIFT_BLOCK_FAILURE,
    },
    // The api returns a 400 error if the shift block cannot be booked and needs to caught by the caller
    useErrorBoundary: false,
    ...options,
  });
}
