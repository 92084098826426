import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { z } from "zod";

import { shiftBlockIncludedMetaDataSchema, shiftBlockSchema } from "./useFetchPaginatedShiftBlocks";

export const shiftBlockResponseSchema = z.object({
  data: shiftBlockSchema,
  included: z.array(shiftBlockIncludedMetaDataSchema),
});

export type ShiftBlockDetail = z.infer<typeof shiftBlockResponseSchema>;

interface GetShiftBlockParams {
  shiftBlockId: string;
  agentId?: string;
}

export function useGetShiftBlock(
  params: GetShiftBlockParams,
  options?: UseGetQueryOptions<ShiftBlockDetail>
) {
  const { shiftBlockId, agentId } = params;
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BFF_URL}/shift-blocks/${shiftBlockId}`,
    queryParams: {
      "filter[agentId]": agentId,
    },
    responseSchema: shiftBlockResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_SHIFT_BLOCK_FAILURE,
      userErrorMessage: "Something went wrong while loading the shift block.",
    },
    ...options,
  });
}
