import { type ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { NotFoundPage } from "../lib";
import { ShiftBlockDetailsPage } from "./ShiftBlockDetailsPage";

export function ShiftBlockRoutes(): ReactElement {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/:shiftBlockId/:agentId?`}>
        <ShiftBlockDetailsPage />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );
}
