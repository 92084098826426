import { post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

const requestToBlockShiftBlockRequestSchema = z.object({
  data: z.object({
    type: z.literal("shift-block-booking-request"),
    attributes: z.object({
      agentId: z.string(),
    }),
  }),
});

const requestToBlockShiftBlockResponseSchema = z.object({
  data: z.object({
    id: z.string(),
    type: z.literal("shift-block-booking-request"),
  }),
});

interface RequestToBlockShiftBlockParams {
  shiftBlockId: string;
}

type RequestToBlockShiftBlockRequest = z.infer<typeof requestToBlockShiftBlockRequestSchema>;
type RequestToBlockShiftBlockResponse = z.infer<typeof requestToBlockShiftBlockResponseSchema>;

export function useRequestToBlockShiftBlock(
  params: RequestToBlockShiftBlockParams,
  options: UseMutationOptions<
    RequestToBlockShiftBlockResponse,
    AxiosError,
    RequestToBlockShiftBlockRequest
  > = {}
): UseMutationResult<
  RequestToBlockShiftBlockResponse,
  AxiosError,
  RequestToBlockShiftBlockRequest
> {
  const { shiftBlockId } = params;
  return useMutation({
    mutationFn: async (data: RequestToBlockShiftBlockRequest) => {
      const response = await post({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/shift-blocks/${shiftBlockId}/booking-requests`,
        data,
        requestSchema: requestToBlockShiftBlockRequestSchema,
        responseSchema: requestToBlockShiftBlockResponseSchema,
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.REQUEST_TO_BOOK_SHIFT_BLOCK_FAILURE,
    },
    // The api returns a 400 error if the shift block cannot be booked and needs to caught by the caller
    useErrorBoundary: false,
    ...options,
  });
}
