import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { type Worker } from "@src/appV2/Worker/api/types";

import { ShiftBlockFilter } from "./components/ShiftBlockFilter";
import { ShiftBlocks } from "./components/ShiftBlocks";

interface ShiftBlockPageProps {
  agent: Worker;
  facilityId?: string;
}

export function ShiftBlockPage(props: ShiftBlockPageProps) {
  const { agent, facilityId } = props;

  return (
    <Stack sx={{ overflowY: "auto", p: 2 }} flex={1} spacing={2}>
      {agent.licensesData.length > 1 && (
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
          <Text>Block for</Text> <ShiftBlockFilter worker={agent} />
        </Stack>
      )}

      <ShiftBlocks worker={agent} facilityId={facilityId} />
    </Stack>
  );
}
