import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { bookabilityStatusResponseSchema } from "@src/appV2/OpenShifts/ShiftAction";
import { z } from "zod";

const shiftBlockBookabilityStatusRequestSchema = z.object({
  facilityId: z.string(),
  agentId: z.string(),
  shiftIds: z.string(),
  returnFirstUnmetCriteria: z.boolean().optional(),
});
type ShiftBlockBookabilityStatusRequest = z.infer<typeof shiftBlockBookabilityStatusRequestSchema>;

type ShiftBlockBookabilityDetail = z.infer<typeof bookabilityStatusResponseSchema>;

type GetShiftBlockBookabilityParams = Omit<ShiftBlockBookabilityStatusRequest, "shiftIds"> & {
  shiftIds: string[];
};

export const shiftBlocksBookabilityApiUrl = `${environmentConfig.REACT_APP_BASE_API_URL}/bookability-status/facility`;

export function useShiftBlockBookability(
  params: GetShiftBlockBookabilityParams,
  options?: UseGetQueryOptions<ShiftBlockBookabilityDetail>
) {
  const { facilityId, agentId, shiftIds, returnFirstUnmetCriteria = true } = params;
  const queryParams: ShiftBlockBookabilityStatusRequest = {
    facilityId,
    agentId,
    shiftIds: shiftIds.join(","),
    returnFirstUnmetCriteria,
  };
  return useGetQuery({
    url: shiftBlocksBookabilityApiUrl,
    queryParams,
    requestSchema: shiftBlockBookabilityStatusRequestSchema,
    responseSchema: bookabilityStatusResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_SHIFT_BLOCK_FAILURE,
      userErrorMessage: "Something went wrong while loading the shift block.",
    },
    ...options,
  });
}
